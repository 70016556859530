/* @media screen and (max-width: 480px) { */
    
@media screen and (max-width: 600px) { 

    .pre-btn, .next-btn {
        border:none;
        width: 60px;
        height: 100%;
        position: relative;
        top:0;
        display: flex;
        justify-content: cneter;
        align-items: center;
        background-color: rgba(0,0,0,0); 
        
    }
    .pre-btn:focus, .next-btn:focus{
        outline: none;
        border: none;
    }
    .next-btn{
        right:0;
    }
    .next-btn p, .pre-btn p{
        font-size: 50px;
        background-color: rgba(255, 255, 255, 0.412);
        border-radius: 10px;
        color: rgba(0,0,0);
    }

    .product-carousel{
    overflow: hidden;
    position: relative;
    }
    .accordion{
        font-size: 20px;
        display: flex;
        margin: 13px 3%;
        overflow-x: auto;
        scroll-behavior: smooth;
    }
    .wave-container{
        /* height: 1rem;
        display: inline-block;
        position: relative;
        left: 0rem;
        top: -3rem;
        margin: 2rem 0rem; */
        display: none;
    }
    .accordion_content.active{
        padding: 2%;
    }
}
.sm_accordion_content{
    height: auto;
    opacity: 1;
    visibility: visible;
    padding: 10% 6%;
    padding-bottom: 0.5rem;
    margin: 3%;
    margin-top: 22%;
    margin-bottom: 15px;
    background: linear-gradient(176deg, rgba(226,87,154,1) 17%, rgba(153,27,87,1) 51%, rgba(129,2,63,1) 83%);
    border-radius: 2rem;
    color: white;
}


.sm_row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 70vw;
    margin: 0.75rem;
    visibility: visible;
    opacity: 1;
    height: 34rem;

}
.sm_column{
    display: flex;
    flex-direction: column;
    flex-basis: 35%;
    align-items: flex-start;
    justify-content: space-around;
    visibility: inherit;
    text-align: left;
    font-size: 2rem;
}
.sm_row .sm_column:nth-child(1){
    height: 0;
}
.sm_column h3{
    font-size: 1.65rem;
    margin: 1.1rem 0;
}
.sm_img{
    width: 42vw;
    height: 29vh;
    position: relative;
    left: 5rem;
    bottom: 1.4rem;
}
#img-04{
    transform: rotate(45deg);
}

.sm_accordion_para{
    text-align: left;
    font-size: 1.012rem;
}
.sm_white-border{
    border: 2px solid white;
    color: white;
    font-size: 1.068rem;
}
.sm_white-divider{
    display: block;
    height: 0.125rem;
    width: 12rem;
    margin: 0.6rem 0.15rem;
    background-color: white;
}
.sm_accordion_span{
    margin-right: 2vw;
    padding-right: 2vw;
    width: 12rem;
    display: block;
    font-size: 1.023rem;
}

/* } */