.Aboutcontainer{
    background-color: #f8f9fa !important;
    /* background-color: #ffdeea; */
    padding: 20px;
    /* height: 200px; */
}
.aboutimagestyle{
    margin: 14px;
    padding: 20px;
}
.imageshape{
    border-radius: 20px;
    border-left: 10px solid #2bbbad;
}
.aboutheading{
    font-size: 25px;
    font-weight: bold;
    margin-top: 34px;
    color: #555;
}
.aboutsubheading{
    color: #f2579d !important;
}
.aboutline {
    width: 50px;
    height: 3px;
    background-color: #f2579d;
    margin: 8px auto;
}
.aboutintroduction{
    background-color: #ffff;
    /* background-color:rgb(246,246,238) ; */
    /* height: 521px; */
    margin-top: 34px;
    box-shadow: 0 2px 5px 0 rgba(242, 87, 157, 0.16), 0 2px 10px 0 rgba(242, 87, 157, 0.12);
}
.aboutusbuttonsection{
    text-align: left;
    border-bottom: 1px solid #2bbbad;
    padding-top: 9px;
}
.aboutusbutton{
    font-size: 17px;
    padding: 0px;
    border-radius: 1px;
    background-color: #f2579d !important;
    width: 143px;
    height: 41px;
}
.aboutparagraph{
    font-size: 14px;
    text-align: justify;
    /* font-family: Roboto,sans-serif; */
    line-height: 28px;
    /* padding: 25px; */
    color: #555;
}
.aboutparagraphsection{
    padding: 25px;
}
.knowmorebutton{
    font-size: 17px;
    padding: 0px;
    border-radius: 1px;
    background-color: #f2579d !important;
    width: 260px;
    height: 41px;
}
.knowmoresection{
    text-align: left;
}