.contactform{
    z-index: 21;
    position: absolute;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,4.16), 0 2px 10px 0 rgba(0,0,0,4.12);
    width: 29%;
    /* width: 75%; */
    margin-left: 10vw;
    margin-top: 116px;
}
.contactcontainer{
    width: 0% !important;
}
@media only screen and (max-width: 600px) {
    .contactcontainer{
        width: 100% !important;
    }
    .contactform{
        z-index: 21;
        position: absolute;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,4.16), 0 2px 10px 0 rgba(0,0,0,4.12);
        width: 75%;
        margin-left: 10vw;
        margin-top: 116px;
    }
  }
.md-form label{
    color: #fff;
}
.formheading{
    color: #fff;
}
.sendbutton{
    color: #fff !important;
    background-color: transparent !important;
    border: 2px solid #fff !important;
}
/* .btn-outline-secondary{
    color: #fff !important;
    background-color: transparent !important;
    border: 2px solid #fff !important;
} */
.form-control{
    color: #fff !important;
}
.grey-text > .md-form{
    display: flex;
}