.Carouselcontainer{
    z-index: 0;
} 
.rocket-body{
    z-index: -2;
}

.rocket-container{
    position: absolute;
    bottom: -7%;
    left: 1vw;
    /* height: 50px; */
    transform: translateX(-50%);
    cursor: pointer;
    transition: all 2.5s;
    z-index: 9;
}

.rocket img{
    transform: scale(0.8,0.8);
    height: 50px;
    /* background-color: rgb(9, 131, 237); */
    
}
.rocket-container.active{
    bottom: 100%;
    left: 100%;
}