@media screen and (min-width:430px) {
  


.nav-changed-heading{
    
}
.nav-changed-heading:after{
    content: "";
    display: block;
    width: 6rem;
    height: 3px;
    border: 2px solid #de5396b5;
    color: #de5396b5;
    background-color: #de5396b5;
    margin: 0% 1rem;
    visibility: hidden;
}
 
.nav-changed-heading:hover:after{
    visibility: visible;
    width: 6rem;
    height: 3px;
    /* margin: 1px 7px 0 8px; */
    transform: rotate(-359.36deg);
    background-color: #f2579d;
}

#nav-contactus{
  /* padding: 10px 0; */
  padding-top: 1.4%;
}

/* to show dropdown onhover on navbar */
.dropdown:hover .dropdown-Changes {
  display: flex;
 
}

.dropdown-Changes{
    /* left: -42.5rem !important; */
    height: 30rem;
    width: 91rem; 
    width: 1440px;
    height: 529px;
    top: 82px;
    background-color: rgba(224, 184, 184, 0.3);
    will-change: transform;
    transform: translate3d(-648px, 43px, 0px);
}

.dropdown-box{
    display: flex;
    flex-direction: row; 
    
}

.dropdown-column{
    width: 100%;
    height: 29rem;
    padding-top: 3%;
    margin: 5px 3%;
}

/* 
.dropdown-button{
    width: 45%;
    height: 24%;
    background: #a6c;
    margin: 0.1% 21%;
    outline: #fff;
}

.dropdown-para{
    font-size: 2.70rem;
    color: white;
} */


.drop-hide{
  display: none;
}
.drop-show{
  display: block;
}

/*  nav bar Suggestions */

/* ## Overview css */
.overview-navigation {
  display: inline-block;
  width: 339px;
  height: 73px;
  margin: 23px 106px 2px 95px;
  padding: 0 0 0 12px;
  background-color: rgba(198, 138, 138, 0.76);
}

/* ## Services Css */

.services-navigation {
  display: inline-block;
  width: 339px;
  height: 73px;
  margin: 2px 106px 88px 95px;
  background-color: rgba(234, 185, 185, 0.47);

}





/* ## background mask color of navigation section */

/* rgba(224, 184, 184, 0.3)
or 
#e0b8b8

width: 1440px;
height: 529px;
top: 82px; */





.overview-text {
  width: 327px;
  height: 73px;
  object-fit: contain;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke: 1px #000;
  font-family: Basic;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: none;
  outline: none;
}


.service-text {
  width: 327px;
  height: 73px;
  margin: 90px 110px 96px 103px;
  object-fit: contain;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke: 1px #000;
  font-family: Basic;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: none;
  outline: none;
}
.service-text:focus{
  outline: none;
}
.overview-text:focus{
  outline: none ;
}

.btnFocused{
  background-color:#f2579d9e;
  outline: none ;
  border: none;

}



.empowering-text {
  width: 807px;
  height: 310px;
  margin: 0 0 199px 106px;
  margin: 0 0 199px 1px;
  font-family: Basic;
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: 4.8px;
  text-align: left;
  color: #fff;
  
}



/* ## pink line below whatwe do css */

.underline-nav {
  width: 90px;
  height: 3px;
  margin: 1px 28px 0 8px;
  transform: rotate(-359.36deg);
  background-color: #f2579d;
}

}