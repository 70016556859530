.Carouselcontainer{
    width: 100vw !important;
    /* min-height:90vh !important; */
    
}
@media only screen and (min-width: 600px) {
    .Carouselcontainer {
        min-height:70vh !important;
    }
  }

@media only screen and (max-width: 600px) {
    .bannerImage{
        height:650px;
    }
}

.Navbarcustom{
    position: fixed !important;
    z-index: 1000 !important;
    width: 100vw !important;
    /* background-color: rgba(255,255,255,0.4); */
    padding: 0px!important;
    border: none !important;
    transition: 500ms ease;
}
.Navbarcustomnew{
    position: fixed !important;
    z-index: 1000 !important;
    width: 100vw !important;
    background-color: #fff;
    /* background-color: rgba(255,255,255,0.4); */
    /* background-color: #d5d5d5; */
    /* background-image: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);; */
    /* background-color: #221F23; */
    padding: 0px!important;
    border: none !important;
    transition: 500ms ease;
}

.navcustomtext{
    color: #fff;
}







.navbar{
    box-shadow: none !important;
}
.navbarlogo{
    height: 80px !important;
    width: 180px !important;
}
.Navbarpadding{
    padding: 0px!important;
}
.navalign{
    margin-left: 30vw !important;
}
.nav-item{
    font-weight: bolder !important;
}
.dropdown-menu{
    background-color: rgba(255,255,255,0.3) !important;
}
.navbar .dropdown-menu a:not(.active) {
    /* color: #fff; */
}
/* .navbar .dropdown-menu a .active {
    color: black;
} */
.navbar .dropdown-menu a:hover {
    /* background-color: rgba(3, 1, 1, 0.3); */
}

.navTextColor{
    color: #fff !important;
}

.navTextColornew{
    color: black !important;
}
@media screen and (min-width:769px) {
    .navTextColor:hover {
        color: #fff !important;
    
    }
    .navTextColornew:hover{
        color: black !important;
    
    }

    /* adjusting dropdown position  */

    .navbar-nav .nav-item:nth-child(1) .dropdown-Changes {
        /* left: -42.5rem !important; */
        transform: translate3d(-39rem, 43px, 0px)!important;
    }
    .navbar-nav .nav-item:nth-child(2) .dropdown-Changes {
        /* left: -51.5rem !important; */
        transform: translate3d(-48rem, 43px, 0px) !important;
    }
    .navbar-nav .nav-item:nth-child(3) .dropdown-Changes {
        /* left: -60.5rem !important; */
        transform: translate3d(-57rem, 43px, 0px) !important;
    }
}



@media screen and (max-width:768px) {
    .navToggleWhite{
        filter: brightness(1) ;
    }
    .navToggleDark {
        filter: brightness(0.1) ;
    }
    .navTextColor , .navTextColornew{
        color: #fff !important;
    
    }
    
    .navalign {
        margin-left: 1vw !important;
    }
    .navbar .dropdown-menu {
        position: relative !important;
        top:2px;
        left: 5px;
        margin: 5px;
        transform: none !important;
        background-color: #555 !important;
        will-change: none !important;
        
    }
}