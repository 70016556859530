.timeline-container{
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
} 

.main-timeline{
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 64vh;
    justify-content: flex-start;
    overflow: hidden;
}
.timeline{
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 1% 6%;
    position: relative;
    top: 0vh;
    margin: 0% 1%;
    transition: all 0.85s ease-in 0.2s;
}
.timeline-year{
    border: 2px solid #af8397;
    height: 65%;
    margin: 16vh 20vw;
    margin-bottom: 17vh;
    width: 0;

}
.timeline-year span{
    position: relative;
    z-index: 3;
    top: -24vh;
    left: -17vh;
    font-size: 8rem;
    color: #af8397;

}

.timeline-content-right img{
    height: 39vh;
    width: 24vw;
}

.side-timeline{
    display: flex;
    flex-direction: column;
    height: 64vh;
    justify-content: flex-start;
}

.timeline-btn{
    display: flex;
    flex-direction: column;
    background-color: #af8397;
    border-radius: 50%;
    position: absolute;
    z-index: 3;
    top: 405vh;
    margin: 0% 0.25%;
}
.timeline-btn > span{
    transform: rotate(-90deg);
    margin: 0 4px;
    padding: 0 11px;
    color: white;
    font-size: 1.15rem;
}
.side-timeline-year {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    overflow: hidden;
    padding: 0;
    margin: 0;
    /* background: black; */
}
.timeline-year-mob{
    display: none;
}
.side-timeline-year > span{
    margin: 1% 0%;
    margin-bottom: 56vh;
    padding: 0 0;
    height: 6%;
    position: relative;
    top: 0%;
    transition: all 0.3s ease-in;
    /* background: aqua; */
}
.side-timeline-year > span::after{
    content: "";
    display: block;
    height: 54vh;
    width: 0px;
    border-left: 7px dotted #c9c9ce;
    position: relative;
    top: 0;
    z-index: 1;
    margin: 0% auto;
    
    /* background-color: yellow; */
}
.side-timeline-year > span:last-child::after{
   display: none;
}




@media only screen and (max-width: 426px){
    .timeline-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-around;
        flex-wrap: wrap;
    } 
    
    .main-timeline{
    
        width: 100%;
        height: 70vh;
        
    }
    .timeline{
        
        height: 100%;
        padding: 1% 1%;
        top: 0vh;
        margin: 0% 1%;
    }
    .timeline-year{
        height: 52vh;
        margin: 10vh 21vw;
    
    }
    .timeline-year span{
        top: -14.5vh;
        left: -9vh;
        font-size: 4rem;
    
    }
    .timeline-content-right {
        height: 70vh;
        width: 100%;
    }
    .timeline-content-right img{
        height: 52%;
        width: 100%;
    }
    
    .timeline-content-right p{
       font-size: 0.8rem;
       margin-bottom: 1%;
    }
    
    .side-timeline{
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: inherit;
    }
    .timeline-year-desktop{
        display: none;
    }
    .timeline-year-mob{
        display: block;
    }
    
    
    
    .timeline-btn{
        flex-direction: row;
        position: absolute;
        left: 15vw;
        z-index: 3;
        top: auto;

    }
    .timeline-btn > span{
        
        font-size: 1.3rem;
        transform: rotate(180deg);
        padding: 2px 4px;
        margin: 4px 2px;

    }
    .side-timeline-year{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        padding: 0 0;
        margin: 0 0px;
        overflow: hidden;
        /* background-color: black; */

    }
    .side-timeline-year span{
        margin: 0 1%;
        margin-right: 69%;
        padding: 0 0;
        width: 14%;
        position: relative;
        left: 0%;
        transition: all 0.3s ease-in;

        /* background-color: aqua; */

    }
    .side-timeline-year span::after{
        content: "";
        display: block;
        height: 0px;
        width: 65vw;
        border-bottom: 7px dotted #c9c9ce;
        position: relative;
        left: 68%;
        top: -20px;
        z-index: 0;
        margin: 0 35%;
        margin-left:35%;
        /* background-color: yellow; */
    }
    .side-timeline-year span:last-child::after{
        display: none;
        
    }
    
}
@media only screen and (max-width: 322px){
    .whoweare-page .section-timeline{
        padding: 0% 1%;
        margin: 1% 0%;
    }
    .timeline{
        padding: 1% 0%;
    }
    .timeline-year span{
        top: -14.5vh;
    }
    .timeline-content-right img{
        height: 43%;
        width: 85%;
    }
    .timeline-btn > span{
        font-size: 1.28rem;
        padding: 1px 3px;
        margin: 3px 1px;
    }

    .side-timeline-year span{
        font-size: 1.3rem;
    }
    .side-timeline-year span::after{
        top: -18px;
    }



}

