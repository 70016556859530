.section-banner-img{
    height: 87vh;
    width: 100vw;
 
}
.section-banner-img .banner-img{
    height: 100%;
    width: 100vw;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://plus.unsplash.com/premium_photo-1674777843441-04dd6cd672ee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    

}
.section-banner-img p{
    font-size: 24px;
    position: relative;
    left: -42rem;
    top: -50%;
    color: white;
}
.section-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}
.section-container .row{
    display: flex;
    width: 100vw;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.section-container .row:nth-child(2){
    background-color: rgb(185, 183, 183); 
}
.section-container .col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.3rem 3.1rem;
}
.section-container .col h3{
    font-size: 2.5rem;
    font-weight: bold;

}
.section-container .col p{
    font-size: 1.5rem;

}
.section-container .col img{
    width: 75%;
}
.section-services{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 2% 4rem ;
    background-color: #5f084c;
    -webkit-mask-image: linear-gradient(357deg, #af1985, #38032d);
    color: white;
}
.section-services .services-box{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  

}
.services-box .service{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 2% 0;
}

.section-services h5{
    font-size: 1.5rem;
    font-weight: bold;
    align-self: flex-start;
    margin-left: 6px;
    text-transform: capitalize;
    
}

.section-services .service h6 {
    font-size: 1.58rem;
    position: relative;
    top: 1px;
    left: 5px;
    z-index: 1;
    color: white;
    text-align: left;
}

.section-services .service .img-container {
    height: 14.5rem;
    width: 19.5rem;
    position: relative;
    top: -5px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://plus.unsplash.com/premium_photo-1674777843441-04dd6cd672ee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80);
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;

}
.section-services .service img {
    width: 19.5rem;
}

.section-services .view-more-btn {
    border: 2px solid white;
    border-radius: 32px;
    padding: 0.5rem 1.5rem;
    margin: 2% auto;
    font-size: 1rem;
    cursor: pointer;
}
.section-services .view-more-btn .arrow-up {
    font-size: 1.4rem;
    margin: 3px;
    padding: 3px;
}
