@media only screen and (max-width: 426px){
    .section-banner-img{
        height: 115vh;
        width: 100vw;
        /* background-color: #5f084c; */
    }

    .section-banner-img .banner-img{
        width: 100vw;
        height: 100%;
        
    }

    .section-container{
        margin-top: 3rem;
        /* background-color: aqua; */
    }

    .section-container .row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    .section-container .row:nth-child(2){
        background-color: rgb(185, 183, 183);
        flex-direction: column-reverse;
    }
    .section-container .col{
        padding: 1.3rem 3.1rem;
        /* background-color: brown; */
    }

    .section-container .col h3{
        font-size: 1.8rem;
        font-weight: bold;
    }

    .section-container .col p{
        font-size: 1.3rem;
    }
    .section-container .col img{
        width: 95%;
    }

    .section-services{
        flex-direction: column;
        padding: 4vh 0;
    }

    
    .section-services .services-box{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    }
    
    .services-box .service{
        display: flex;
        height: 22vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .section-services h5{
        font-size: 1.5rem;
        font-weight: bold;
        align-self: flex-start;
        text-transform: capitalize;
        margin-left: 4%;
    }
    .section-services .service .img-container{
        height: 16vh ;
        width: 44vw;
        margin: 1px 1px;
        border-radius: 10px;
    }

    .section-services .service h6{
        font-size: 1.1rem;
        position: relative;
        top: 1px;
        left: 10px;
        z-index: 1;
        color: white;
        text-align: left;
    }

    .section-services .view-more-btn{
        border-radius: 32px;
        padding: 1% 3%;
        margin: 5% auto;
        font-size: 1.25rem;
    }
    



}

@media only screen and (max-width: 376px){
    .section-services .service .img-container{
        height: 6.5rem;
        width: 8.7rem;
        margin: 2px 1px;
        border-radius: 28px;
        top: 0;

    }
    .section-services .service h6{
        font-size: 0.91rem;
    }



}
@media only screen and (max-width: 320px){
    .section-services .service .img-container{
        height: 6rem;
        width: 8.1rem;
        margin: 2px 1px;
        border-radius: 28px;
        top: 0;

    }



}
