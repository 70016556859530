.banner-div{
    /* -webkit-mask-image: radial-gradient(circle, black 50%, rgba(0, 0, 0, 0.5) 50%); */
    mask-image: radial-gradient(circle, black 50%, rgba(0, 0, 0, 0.5) 50%);
    -webkit-mask-image: radial-gradient(circle, #311e1e 50%, rgb(4 0 0) 50%);
}



.banner-div .banner-img {
    /* background-image: url(); */
    width: 100%;
    mask-image: url(https://images.unsplash.com/photo-1421986527537-888d998adb74?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY4NTAzMDU3Mw&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080);
    background-position: center top;
    background-repeat: no-repeat;
    height: 125vh; /* adjust the height as per your requirement */
  }
  
  @media only screen and (max-width: 426px){
    .banner-img{
        height: 119vh;
    }
    
}