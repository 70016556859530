.featureicon{
    padding-left: 31px !important;
    padding-right: 0px !important;
}
.featurecontent{
    padding-left: 18px !important;
    text-align: justify !important;
    padding-right: 45px !important;
}
/* .servicesrow{
    margin-left: 26px !important;
    margin-right: 26px !important;
} */
@media only screen and (min-width: 600px) {
    .servicesrow{
        margin-left: 26px !important;
        margin-right: 26px !important;
    }
  }
  .servicessection{
    /* background: rgb(238,174,202);
    background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%); */
    /* background: #00416A;   */
    /* fallback for old browsers */
    /* background: -webkit-linear-gradient(to left, #E4E5E6, #00416A);  */
     /* Chrome 10-25, Safari 5.1-6 */
    /* background: linear-gradient(to left, #E4E5E6, #00416A);  */
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    /* background-color: #f5f5f5; */
     background-image: url(../../images/section-back2.jpg);
    background-color:rgba(255,255,255,0.3); 
    z-index: -250 !important;
    /* background-image: linear-gradient(120deg, #f5f5f5 0%, #ebedee 100%); */
    /* background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%); */
    /* background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%); */
    /* background-image: linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%); */
    /* background-image: linear-gradient(to top, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);   */
    /* background-image: linear-gradient(-225deg, #7DE2FC 0%, #B9B6E5 100%); */

}
.servicesbuttoncolor{
    /* background: #f2579d !important; */
    font-weight: bold;
    color: #24625f;
    background: rgb(242,87,157);
    background: linear-gradient(186deg, rgba(242,87,157,1) 0%, rgba(255,255,255,1) 100%);
}
.servicesiconcolor{
    color: #f2579d !important;
}