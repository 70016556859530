*{
    margin: 0;
    padding: 0;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    box-sizing: border-box;
}

body{
    overflow: scroll;
}
/* @media only screen and (min-width: 768px) { */

.products{
    margin: 3.5rem 1.5rem;
}
.white-border{
    border: 2px solid white;
    color: white;
    font-size: 20px;
}
.white-border:hover{
    color: white;
}
h1{
    text-align: center;
}
.accordion{
    font-size: 20px;
    margin: 1rem 3%;
    /* overflow-x: hidden; */
    scroll-behavior: smooth;
    /* background-color: #002350; */
}
.accordion_container{
  margin: 15px;
  background: rgb(226,87,154);
  background: linear-gradient(176deg, rgba(226,87,154,1) 17%, rgba(153,27,87,1) 51%, rgba(129,2,63,1) 83%);
  border-radius: 2em;
color: white;

}

.accordion .row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 1.5rem;
    visibility: hidden;
}
.row.active{
    visibility: visible;
    opacity: 1;
}
.row-2{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 0px 2.5rem;
}

.column{
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    align-items: flex-start;
    justify-content: space-around;
    visibility: inherit;
}

.accordion_button span{
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #81023F;
    font-size: 40px;
}

.accordion_button:focus{
    outline: none;
}

.accordion_content {
    padding: 0 20px;
    opacity: 0;
    visibility: hidden;
    transition: all .5s;
    height: 0;
    margin: 0;
    margin-top: -5px;
}
.accordion_button{
    margin: 20px 0;
    padding: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: inherit;
    background-color:#ffffff;
    border : 0px;
    border-bottom: 6px solid #81023F;

}
.accordion_button::after{
    background-color: #81023F;
}


.accordion_content.active{
    height: auto;
    opacity: 1;
    visibility: visible;
    opacity: 1;
    padding: 15px;
    margin-bottom: 15px;
}


.accordion_para{
    text-align: left;
}

.accordion_span{
    margin-right: 13px;
    padding-right: 5px;
    width: 19%;
    display: inline-block;
    vertical-align: middle;
}

.accordion_para span{
    height: 75px;
}
/* .accordion_para span+div:nth-child(-2n+4){
    
    background-color: white;
    
} */

.white-divider{
    display: inline-block;
    height: 80px;
    width: 2px;
    margin: 10px;
    vertical-align: middle;
    background-color: white;
}
.icon{
    margin: 2px;
    padding: 2px;
    color: white;
    font-size: 20px;
}


div button img {
    height: 3.8rem;
    display: inline;
    vertical-align: center;
}
p img{
    width: 30%;
    height: 50vh;
    position: relative;
    left: 20%;
    bottom: 40vh;
    
}
div div.column img{
    width: 90%;
    height: 40vh;
    position: relative;
    left: 20%;
    bottom: 19vh;
}
.arrow-up{
    font-size: 2.3rem;
    margin: 5px;
    padding: 5px;
}
#img-04{
    transform: rotate(45deg);
}
.Img:nth-child(3){
    transform: rotate(45deg);
}
  
/* } */


/* wave-line animation starts here */
.wave-container{
    height: 1rem;
    width: 100%;
    display: inline-block;
    position: relative;
    left: -2rem;
    top: 18rem;
}





.line-body-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    /* background: white; */
    flex-direction: row;
    flex-wrap: wrap;
}
.line-body-wrapper svg{
    position: relative;
    width: 100%;
    height: 18rem;
    top: -15rem;
    fill: none;
    margin: 10px;
    z-index: -3;
}
.line-body-wrapper svg:nth-child(2){
    top: -38rem;
    
}
.line-body-wrapper svg:nth-child(3){
    top: -55.5rem;
    
}
.line-body-wrapper svg path{
    d: path("M 0, 25 C350, 430 800, -430 1440, 25");
    stroke: #c1c0c0;
    stroke-width: 2px;
    stroke-linecap: round;
    transform: translate(50px,50%);
    animation: line-animate 5s ease-in-out infinite ;
}
.line-body-wrapper svg:nth-child(2) path{
    d:path("M 0, 25 C350, 120 990, 120 1440, 25");
    animation: line-animate-2 5s ease-in-out infinite ;
    animation-delay: -1s;
}
.line-body-wrapper svg:nth-child(3) path{
    d:path("M 0, 25 C350, 300 800, -300 1440, 25");
    stroke-dasharray: 1 20;
    stroke-linecap: round;
    stroke-width: 8px;
    animation: line-animate-dotted 5s ease-in-out infinite;
    animation-delay: -3s;
}
.line-body-wrapper::before{
    content: "";
    position: relative;
    width: 10px;
    height: 10px;
    background-color: #c1c0c0;
    border-radius: 50%;
    left: -43.7rem;
    top: -5.5rem;
}

.line-body-wrapper::after{
    content: "";
    position: relative;
    width: 10px;
    height: 10px;
    background-color: #c1c0c0;
    border-radius: 50%;
    left: 46.6rem;
    top: -64.01rem;
}

@keyframes line-animate {
    0%{
        d:path("M 0, 25 C350, 430 800, -430 1440, 25");
    }
   
    50%{
        d: path("M 0, 25 C350, -430 800, 420 1440, 25");
    }
   
}
@keyframes line-animate-dotted {
    0%{
      d:  path("M 0, 25 C350, 300 800, -300 1440, 25");
    }
   
    50%{
        d: path("M 0, 25 C350, -300 800, 300 1440, 25");
    }
   
}
@keyframes line-animate-2 {
    0%{
        d:path("M 0, 25 C350, 120 990, 120 1440, 25");
    }
   
    50%{
        d:path("M 0, 25 C350, -120 990, -120 1440, 25");
    }
   
}

   

