.whoweare-page .section-banner{
  height: 125vh;
}
.whoweare-page .section-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 85vh;

}
.whoweare-page .section-container .section-text{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin: 1px 3vw;
    margin-left: 12vw;
}
.whoweare-page .section-container .section-text h4{
  font-size: 2.5rem;
  padding: 1% 7%;
  text-align: left;
  color: #af8397;
    
}
.whoweare-page .section-container .section-text p{
  text-align: left;
  padding: 1% 7%;
  color: #af8397;
   
}
.whoweare-page .section-container .section-img{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 1vw;
    margin-right: 14vw;
    padding: 0vw 1vw;

}

.whoweare-page .section-container .section-img img{
    width: 33vw;
    height: 76vh;
}

.whoweare-page .section-glance{
    display: flex;
    flex-direction: row;
    align-content: space-around;
    justify-content: space-evenly;
    align-items: flex-start;
    background-color: rgb(175 131 151);
    padding: 3vw 1vw;

}
.whoweare-page .section-glance img{
    width: 33vw;
}
.whoweare-page .section-glance p{
    font-size: 1.5rem;
}
.whoweare-page .section-carousel {
    background-color: white;
    padding: 4rem 1%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.whoweare-page .section-carousel > p{
    color: #af8397;
    font-size: 2rem;
    margin-left: 7rem;
}
.whoweare-page .section-timeline{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  margin: 30px;
  color: #af8397;

}
.whoweare-page .section-timeline > span{
  margin: 0 0;
  font-size: 2rem;
  /* color: #af8397; */
}
.whoweare-page .section-investors{
  display: flex;
  padding: 0% 5vw;
  padding-left: 16vw;
  margin-bottom: 5% ;
  flex-direction: column;
  align-items: flex-start;
  width: 60vw;
  text-align: left;
  color: #af8397;
}
.whoweare-page .section-investors img{
  height: 57vh;
  margin: 3% 0%;
  width: 30vw;

}
.whoweare-page .section-investors > span{
  text-align: left;
  font-size: 2.45rem;
  /* margin: 0 2%; */
  padding: 1px 44px;
  padding-left: 5px;
}
.whoweare-page .section-investors > span:nth-child(2){
  font-size: 0.9rem;
  margin: 0 0;
  text-transform: uppercase;
}
.whoweare-page .section-investors p{
  font-size: 1rem;
  color: black;
  text-align: left;
  text-align: start;
  padding-right: 30%;
  color: #af8397;
}
.whoweare-page .section-investors .read-more-btn{
  width: 23%;
  background-color: white;
  color: #af8397;
  border: none;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  cursor: pointer;
}

.whoweare-page .section-investors i{
  border-radius: 50%;
  padding: 0% 2%;
  text-align: center;
  background: #e41165;
  color: white;
  border: 2px solid #e41165;
  display: inline-block;
  outline: none;
}



/* footer styling */

.footer-whoweare {
    background-color: rgb(30 34 34);
    color: white;
    padding: 5% 6%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
  }
  .footer-whoweare div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .footer-whoweare hr{
      display: inline;
      height: 150px;
      background-color    :white ;
      border: 1px solid white;
  }
  
  .footer-whoweare span {
    margin-bottom: 4px;
    font-size: 60px;
  }
  .footer-whoweare p {
    font-size: 20px;
    margin-bottom: 0px 0;
  }
  
  .footer-whoweare h2 {
    margin-bottom: 15px;
  }
  
  .footer-whoweare button {
    border: 1px solid white;
    border-radius: 48px;
    padding: 5px 20px;
    color: white;
    margin-top: 3%;
  }
  
  .footer-whoweare .connect-button {
      background: linear-gradient(to right, rgb(210 21 37), #FF1493);
      padding: 12px 19px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 20px;
      border: none;
      border-radius: 35px;
    }
  
  .footer-whoweare .webokit-button {
    background-color: rgb(30 34 34);
  }
  
  @media only screen and (max-width: 426px){

    .whoweare-page .section-banner{
      height: 116vh;
      /* background-color: blue; */
    }

    .whoweare-page .banner-div .banner-img{
      height: 115vh;
    }

    .whoweare-page .section-container{
      
      flex-direction: column;
      justify-content: space-around;
      height: 125vh;
      align-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 2rem;

  
  }
  .whoweare-page .section-container .section-text{
      padding: 1% 5%;
      margin: 1% 1%;
  }
  .whoweare-page .section-container .section-text h4{
    font-size: 1.6rem;
    padding: 1% 1%;
    padding-right: 13%;
  }
  .whoweare-page .section-container .section-text p{
    font-size: 0.9rem;
    padding: 0 1%;
    padding-right: 5vw;
    margin: 0.5rem 0;
  }
  
  .whoweare-page .section-container .section-img{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 1vw auto;
  
  }
  
  .whoweare-page .section-container .section-img img{
    margin-top: 3%;
    height: 76vh;
    width: 70vw;
  
  }
  
  .whoweare-page .section-glance{
    padding: 3vh 1vw;
  
  }
  .whoweare-page .section-glance img{
      width: 50vw;
  }
  .whoweare-page .section-glance p{
      font-size: 1.1rem;
  }
  .whoweare-page .section-carousel {
      padding: 3rem 1%;
  }
  .whoweare-page .section-carousel > p{
      
      font-size: 1.5rem;
      margin-left: 2.1rem;
  }
  .whoweare-page .section-timeline{
    
    padding: 1% 1%;
    margin: 1% 1%;
    font-size: 0.85rem;
  
  }
  .whoweare-page .section-timeline > span{
    margin: 0 0;
    font-size: 1.7rem;
  }
  .whoweare-page .section-investors{
    padding: 0% 3vw;
    padding-left: 10vw;
    margin-bottom: 6% ;
    width: 72vw;
  }
  .whoweare-page .section-investors img{
    height: 55vh;
    margin: 3% 0%;
    width: 45vw;
  
  }
  .whoweare-page .section-investors > span{
    font-size: 1.35rem;
    padding: 1px 5px;
  }
  .whoweare-page .section-investors > span:nth-child(2){
    font-size: 0.9rem;
    padding: 0;

  }
  
  .whoweare-page .section-investors p{
    font-size: 0.9rem;
    padding-right: 7%;
    }
  .whoweare-page .section-investors .read-more-btn{
    width: 50%;
    font-size: 0.95rem;
    }
  
  /* footer styling */

.footer-whoweare {

  padding: 5% 1%;
}
.footer-whoweare div{
  align-items: flex-start;
  justify-content: flex-start;

}
.footer-whoweare hr{
  height: 20vh;
}

.footer-whoweare span {
  font-size: 1.2rem;
  margin-top: 5%;
}
.footer-whoweare p {
  font-size: 1rem;
}

.footer-whoweare h2 {

  font-size: 1rem;
  margin: 6% 1%;
}

.footer-whoweare button {
  padding: 5px 5px;
 
}

.footer-whoweare .connect-button {
  padding: 10px 10px;
  font-size: 1rem;
  border-radius: 100px;
   
  }

.footer-whoweare .webokit-button .icon {
  font-size: 15px;
}
}

@media only screen and (max-width: 320px){

  .whoweare-page .section-container .section-img img{
    width: 82vw;
    margin-bottom: 0.5rem;
  }

}


