body{
    margin: 0;
    /* background: lightpink; */
    /* text-align: center; */
    /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
    /* color: black; */
}
.container2{
    position: relative;
    width: 9vw;
    margin: 4px auto 0 auto;
    perspective: 2000px;
}

.carousel2{
    position: absolute;
    width: 30%;
    height: 30%;
    transform-style: preserve-3d ; 
    animation: rotate360 60s infinite forwards linear;
}
.carousel2:hover {
  animation-duration: 5s;
  cursor: pointer;
  animation-play-state: paused;
  }


.carousel2_face{
    position: absolute;
    width: 290px;
    height: 145px;
    top: 20px;
    left: 10px;
    right: 10px;
    background-size: cover;
    box-shadow:inset 0 0 0 2000px rgba(0,0,0,0.5);
    display: flex;

}

span{
    margin: auto;
    font-size: 1.5rem;
}
.carousel2_face:nth-child(1) {
    background-image: url("https://images.pexels.com/photos/1141853/pexels-photo-1141853.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
    transform: rotateY(0deg) translateZ(362px); 
  }
.carousel2_face:nth-child(2) {
    background-image: url("https://images.pexels.com/photos/11418529/pexels-photo-11418529.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
    transform: rotateY(45deg) translateZ(362px); 
  }
.carousel2_face:nth-child(3) {
    background-image: url("https://images.pexels.com/photos/11521/pexels-photo-11521.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
    transform: rotateY(90deg) translateZ(362px); 
  }
.carousel2_face:nth-child(4) {
    background-image: url("https://images.pexels.com/photos/11418523/pexels-photo-11418523.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
    transform: rotateY(135deg) translateZ(362px); 
  }
.carousel2_face:nth-child(5) {
    background-image: url("https://images.pexels.com/photos/11418522/pexels-photo-11418522.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
    transform: rotateY(180deg) translateZ(362px); 
  }
.carousel2_face:nth-child(6) {
    background-image: url("https://images.pexels.com/photos/11456/pexels-photo-11456.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
    transform: rotateY(225deg) translateZ(362px); 
  }
.carousel2_face:nth-child(7) {
    background-image: url("https://images.pexels.com/photos/11418156/pexels-photo-11418156.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
    transform: rotateY(270deg) translateZ(362px); 
  }
.carousel2_face:nth-child(8) {
    background-image: url("https://images.pexels.com/photos/1141851/pexels-photo-1141851.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
    transform: rotateY(315deg) translateZ(362px); 
  }

/*  
.map {
  margin-top: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
}
.map > button {
  all: unset;
  background: #11406c;
  cursor: pointer;
  margin: 0 0.125em;
  position: relative;
  user-select: none;
  border-radius: 100%;
  height: 1em;
  width: 1em;
}
.map > button.active:after {
  background: #0184ff;
  content: "";
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  border-radius: 100%;
  height: 0.5em;
  width: 0.5em;
} */



  @keyframes rotate360 {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(-360deg);
    }
  }

 