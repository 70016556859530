

/* Sphere CSS Starts */

.sphereBody{
    height: 123vh;
    background: #000;
    display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
	
	background-image: url(../../images/night-sky-bg.avif);
}
.scene{
	width: 66vmin;
    margin: .5% auto;
    perspective: 175mvin;
    -webkit-box-reflect: below -25 vmin lineargradient(to bottom, #0000 25%, #000);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-around;
    align-items: center;
}
.sphere-wrapper{
    position: relative;
    display: flex;
    /* aspect-ratio: 1; */
    transform-style: preserve-3d;
    justify-content: center;
    align-items: center;
    transform: rotateX(-30deg) rotateY(0deg) rotateZ(-15deg);
    transform-style: preserve-3d;
}
.sphere{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    height: 400px;
    width: 400px;
    transform-style: preserve-3d;
    transform:  rotateY(0deg) ;
    animation: rotate-sphere 30000ms infinite linear 0ms;
}
.vertical-ring{
    position: absolute;
    top:0;
    left:0;
    width: 400px;
    height: 400px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 2px solid hsla(0, 0%, 100%, .2);
    background-color: rgba(0, 0, 0, .5);
}


.vr1{
    transform: rotateY(15deg);
}
.vr1a{
	transform: rotateY(15deg);
	animation-delay:0s;
}
.vr2{
    transform: rotateY(30deg);
}
.vr3{
    transform: rotateY(45deg);
}
.vr4{
    transform: rotateY(60deg);
}
.vr4a{
	transform: rotateY(60deg);
}
.vr5{
    transform: rotateY(75deg);
}
.vr6{
    transform: rotateY(90deg);
}
.vr7{
    transform: rotateY(105deg);
}
.vr7a{
	transform: rotateY(105deg);
    animation-delay: 2s;
}
.vr8{
    transform: rotateY(120deg);
}
.vr9{
    transform: rotateY(135deg);
}
.vr10{
    transform: rotateY(150deg);
}
.vr10a{
	transform: rotateY(150deg);
    border-width: 50px ;
	animation: none;
}
.vr11{
    transform: rotateY(165deg);


}
.vr12{
    transform: rotateY(180deg);


}
.vr0{
    transform: rotateY(0deg);
}

.horizontal-ring{
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 2px solid hsla(0, 0%, 100%, .2);
    transform-style: preserve-3d;
}


.hr1 {
    top: -47px;
    left: 141px;
    width: 138px;
    transform: rotateX(90deg);
}
.hr2{
    top: -80px;
    left: 66px;
    width: 260px;
    transform: rotateX(90deg);
}
.hr3{
    top: -78px;
    left: 30px;
    width: 335px;
    transform: rotateX(90deg);
}
.hr4{
    top: -63px;
    left: 15px;
    width: 370px;
    transform: rotateX(90deg);
}
.hr5{
    top: -13px;
    left: 3px;
    width: 392px;
    transform: rotateX(90deg);
}
.hr6{
    top: 55px;
    left: 9px;
    width: 379px;
    transform: rotateX(90deg);
}
.hr7{
    top: 108px;
    left: 25px;
    width: 353px;
    transform: rotateX(90deg);
}
.hr8{
    top: 144px;
    left: 35px;
    width: 332px;
    transform: rotateX(90deg);
}
.hr9{
    top: 261px;
    left: 101px;
    width: 211px;
    transform: rotateX(90deg);
}
.hr10{
    top: 60px;
    left: 16px;
    /* width: 60%; */
    transform: rotateX(90deg) scale(1);
}
.hr11{
    top: 70px;
    left: 16px;
    /* width: 50%; */
    transform: rotateX(90deg) scale(1);
}
.hr12{
    top: 80px;
    left: 16px;
    /* width: 50%; */
    transform: rotateX(90deg) scale(1);
}

.blue-ring{
    border: 10px solid #0098ff;
	background-color: rgba(0, 0, 0, .5);
	box-shadow: 0 0 32px 0 rgba(0, 152, 255, .5);
    
}
.orange-ring{
    border: 10px solid #ff4500;
	box-shadow: 0 0 32px 10px rgba(255, 69, 0, .4);
}
.animate-ring{
    animation: ringVisibilty 2000ms ease-in-out infinite 1s;
}

.show-ring{
	transition: opacity 500ms ease 0s, transform 500ms cubic-bezier(0.175,0.885,0.3,1.275) 0s;
    opacity: 1;
    transform-style: preserve-3d;
    transform:  scaleX(1.3) scaleY(1.3) scale(1);
	animation: none;
}
.show-ring-hr{
	transition: opacity 500ms ease 0s, transform 500ms cubic-bezier(0.175,0.885,0.3,1.275) 0s;
    opacity: 1;
    transform-style: preserve-3d;
    transform: rotateX(90deg)  scaleX(1.3) scaleY(1.3) scale(1);
	animation: none;
}
.board-ring{
	border-width: 50px;
}
.vr4a.show-ring{
	transform: rotateY(60deg) scaleX(1.3) scaleY(1.3) scale(1);
	border-width: 2px ;
}
.vr7a.show-ring{
	transform: rotateY(105deg) scaleX(1.3) scaleY(1.3) scale(1);
}
.hr3.blue-ring{
	border-width: 2px;
}





.sphere-axsis1{
    position: absolute;
    z-index: 2;
    width: 1px;
    height: 600px;
    background-color: hsla(0, 0%, 100%, .2);
}

.sphere-axsis2{
    position: absolute;
    z-index: 2;
    width: 1px;
    height: 600px;
    background-color: hsla(0, 0%, 100%, .2);
    -webkit-transform: rotateX(0deg) rotateY(90deg) rotateZ(0deg);
    transform: rotateX(0deg) rotateY(90deg) rotateZ(0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}




.title-pulse{
	position: relative;
	display: flex;
    top: -222px;
    left: 39px;
	flex-direction: column;
	padding: 30px;
	color: hsla(0,0%,100%,.75);
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 4px;
	animation: pulse-animate 3000ms ease-in-out infinite 0s;
	

}

.title-pulse-span{
	color: #0098ff;
	font-size: 16px;
	font-weight: 700;
	text-shadow: 0 0 40px rgba(0, 152,255,.8);
}

.title-pulse-hide{
	animation: none;
	opacity: 0;
	transition: opacity 3000ms ease 0s;
}
.drop-heading-wrapper{
	position: relative;
    top: -4vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.drop-heading{
	/* position: fixed; */
    /* left: 0px;
    top: 98px;
    right: 0px; */
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: Montserrat, sans-serif;
    color: hsla(0, 0%, 100%, .8);
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 4px;
    text-transform: uppercase;
}
.drop-heading span{
    color: #0098ff;
}

.drop-arrow1-span{
	position: relative;
    left: 3vw;
    top: 0vh;
    right: 0px;
    display: block;
    margin: 0px auto;
    color: #fff;
    font-size: 16px;
    text-shadow: 0 0 18px #fff;
    cursor: help;
	opacity: 1;
    transition: opacity 500ms ease 0s;
    animation: pulse-animate 3000ms ease-in-out infinite 0s;

}

.drop-para{
	position: relative;
    left: 0px;
    top: 2vh;
    right: 0px;
    display: block;
    width: 47vw;
    margin-right: auto;
    margin-left: auto;
    -webkit-transition: text-shadow 500ms ease;
    transition: text-shadow 500ms ease;
    font-family: Montserrat, sans-serif;
    color: hsla(0, 0%, 100%, .7);
    font-size: 8px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 4px;
	opacity: 0;

}

.drop-arrow1:nth-child(2n):hover +  .drop-para{
	
	opacity: 0.8;
	transition: opacity 3000ms ease 0s;

}
.drop-arrow1:hover .drop-arrow1:nth-child(2n) +  .drop-para:hover{

	opacity: 0.5;
	text-shadow: 0 0 18px #fff;
	transition: opacity 3000ms ease 0s, text-shadow 3000ms ease 0s;

}

.rule-wrapper{
    height: 30px;
    width: 60vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* border: 2px solid red; */
    align-content: space-around;

}
.rule{
	margin: 3px;
	color: hsla(0, 0%, 100%, .3);
	line-height: 18px;
	font-weight: 500;
	letter-spacing: 4px;
	cursor: pointer;
	transition: color 0.5s ease 0s, text-shadow 0.5s ease 0s;
}
.rule:hover{
	color: #fff;
    text-shadow: 0 0 18px #fff;
}
.Txt9:hover{
	color: #ff4500;
    text-shadow: 0 0 18px #ff4500;
}

.rule-desc-wrapper{
	/* border: 2px solid red; */
    position: relative;
    top: -67%;
    left: 4%;
    width: 50vw;
    height: auto;
}

.rule-desc{
	position: absolute;
    /* width: 50%; */
    padding: 1% 1%;
    background-color: rgba(0, 0, 0, .23);
    font-family: Montserrat, sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 4px;
    opacity: 0;
    transition: opacity 100ms ease 0s;

}
.rule-desc-span{
	color: #0098ff;
    font-size: 18px;
    line-height: 40px;
    font-weight: 600;
    text-shadow: 0 0 18px #0098ff;

}
.rule-desc-show{
	opacity: 1;
	transition: opacity 500ms ease 0ms;
}

.footer-para{
	position: relative;
	left: 0;
	right: 0;
	/* bottom: -53px; */
	display: block;
	width: 60%;
	margin: 10px 10px;
	transition: color 500ms ease, text-shadow 500ms ease;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
	color: hsla(0,0%,100%,.2);
	font-size: 8px;
	line-height: 14px;
	text-align: center;
	letter-spacing: 4px;

}
.footer-logo{
	position: relative;
	left: 0;
	right: 0;
	/* bottom: 30px; */
	display: block;
	width: 90px;
	height: 25px;
	margin-right: auto;
	margin-left: auto;
	background-image: url();
	background-position: 50% 50%;
	background-size: 80px;
	background-repeat: no-repeat;
	opacity: 0.2;
	transition: opacity 500ms ease;
}

@keyframes rotate-sphere {
    to{
        transform: rotateY(360deg);
    }
}

@keyframes ringVisibilty {
    0%,100%{
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
    
}
@keyframes pulse-animate {
	0%{
		opacity: 0;
	}
	100%{
		opacity: 0.8;
	}
	
}


/* Sphere CSS End */

/* Particle CSS Start */

.particle-wrapper {
    position: relative;
    top: -250px;
    left: -500px;
    z-index: 0;
}
 .particle {
	 position: absolute;
	 animation: shoot 60s ease-out infinite;
	 animation-name: shoot, fade;
	 border-radius: 50%;
     background: #a3a3f547;
}
.particle:nth-child(odd){
    width: 10px;
    height: 10px;

}
.particle:nth-child(even){
    width: 20px;
    height: 20px;

}
 .particle:nth-child(1) {
	
	 transform: translate(5vw, 77vh);
	
	 animation-duration: 1.08s;
	 animation-delay: -0.9612s;
}
 .particle:nth-child(2) {
	 
	 transform: translate(76vw, 16vh);
	
	 animation-duration: 1.4s;
	 animation-delay: -0.742s;
}
 .particle:nth-child(3) {
	 
	 transform: translate(13vw, 32vh);
	
	 animation-duration: 1.59s;
	 animation-delay: -1.1448s;
}
 .particle:nth-child(4) {
	
	 transform: translate(95vw, 77vh);

	 animation-duration: 1.06s;
	 animation-delay: -0.636s;
}
 .particle:nth-child(5) {
	
	 transform: translate(35vw, 99vh);

	 animation-duration: 1.02s;
	 animation-delay: -1.02s;
}
 .particle:nth-child(6) {
	
	 transform: translate(97vw, 38vh);
	
	 animation-duration: 1.51s;
	 animation-delay: -1.359s;
}
 .particle:nth-child(7) {
	
	 transform: translate(77vw, 10vh);
	
	 animation-duration: 1.09s;
	 animation-delay: -0.3815s;
}
 .particle:nth-child(8) {
	
	 transform: translate(3vw, 10vh);
	
	 animation-duration: 1.79s;
	 animation-delay: -0.179s;
}
 .particle:nth-child(9) {
	
	 transform: translate(8vw, 53vh);
	
	 animation-duration: 1.22s;
	 animation-delay: -0.5368s;
}
 .particle:nth-child(10) {
	 
	 transform: translate(54vw, 5vh);
	
	 animation-duration: 1.64s;
	 animation-delay: -0.6396s;
}
 .particle:nth-child(11) {
	
	 transform: translate(71vw, 85vh);
	
	 animation-duration: 1.47s;
	 animation-delay: -1.4406s;
}
 .particle:nth-child(12) {
	 transform: translate(84vw, 45vh);
	
	 animation-duration: 1.24s;
	 animation-delay: -0.4092s;
}
 .particle:nth-child(13) {
	 transform: translate(33vw, 37vh);
	
	 animation-duration: 1.21s;
	 animation-delay: -0.0242s;
}
 .particle:nth-child(14) {
	 transform: translate(52vw, 62vh);
	
	 animation-duration: 1.3s;
	 animation-delay: -0.442s;
}
 .particle:nth-child(15) {
	 transform: translate(78vw, 28vh);
	
	 animation-duration: 1.85s;
	 animation-delay: -1.295s;
}
 .particle:nth-child(16) {
	 transform: translate(24vw, 3vh);
	
	 animation-duration: 1.21s;
	 animation-delay: -0.2783s;
}
 .particle:nth-child(17) {
	 transform: translate(81vw, 49vh);
	
	 animation-duration: 2s;
	 animation-delay: -0.04s;
}
 .particle:nth-child(18) {
	 transform: translate(90vw, 73vh);
	
	 animation-duration: 1.74s;
	 animation-delay: -0.4698s;
}
 .particle:nth-child(19) {
	 transform: translate(30vw, 6vh);
	
	 animation-duration: 1.03s;
	 animation-delay: -0.6386s;
}
 .particle:nth-child(20) {
	 transform: translate(54vw, 87vh);
	
	 animation-duration: 1.28s;
	 animation-delay: -1.2416s;
}
 .particle:nth-child(21) {
	 transform: translate(92vw, 60vh);
	
	 animation-duration: 1.23s;
	 animation-delay: -0.738s;
}
 .particle:nth-child(22) {
	 transform: translate(19vw, 99vh);
	
	 animation-duration: 1.68s;
	 animation-delay: -1.0752s;
}
 .particle:nth-child(23) {
	 transform: translate(21vw, 11vh);
	
	 animation-duration: 1.64s;
	 animation-delay: -1.2956s;
}
 .particle:nth-child(24) {
	 transform: translate(51vw, 42vh);
	
	 animation-duration: 1.47s;
	 animation-delay: -0.9114s;
}
 .particle:nth-child(25) {
	 transform: translate(33vw, 26vh);
	 animation-duration: 1.93s;
	 animation-delay: -0.6755s;
}
 .particle:nth-child(26) {
	 transform: translate(82vw, 50vh);
	 animation-duration: 1.83s;
	 animation-delay: -1.2993s;
}
 .particle:nth-child(27) {
	 transform: translate(39vw, 78vh);
	 animation-duration: 1.57s;
	 animation-delay: -0.0314s;
}
 .particle:nth-child(28) {
	 transform: translate(18vw, 41vh);
	 animation-duration: 1.84s;
	 animation-delay: -0.2944s;
}
 .particle:nth-child(29) {
	 transform: translate(16vw, 21vh);
	 animation-duration: 1.5s;
	 animation-delay: -1.2s;
}
 .particle:nth-child(30) {
	 transform: translate(62vw, 14vh);
	 animation-duration: 1.27s;
	 animation-delay: -1.1557s;
}
 .particle:nth-child(31) {
	 transform: translate(100vw, 2vh);
	 animation-duration: 1.15s;
	 animation-delay: -0.5635s;
}
 .particle:nth-child(32) {
	 transform: translate(60vw, 25vh);
	 animation-duration: 1.8s;
	 animation-delay: -0.252s;
}
 .particle:nth-child(33) {
	 transform: translate(37vw, 18vh);
	 animation-duration: 1.37s;
	 animation-delay: -1.0138s;
}
 .particle:nth-child(34) {
	 transform: translate(73vw, 6vh);
	 animation-duration: 1.37s;
	 animation-delay: -0.7672s;
}
 .particle:nth-child(35) {
	 transform: translate(6vw, 40vh);
	 animation-duration: 1.86s;
	 animation-delay: -1.1532s;
}
 .particle:nth-child(36) {
	 transform: translate(79vw, 67vh);
	 animation-duration: 1.7s;
	 animation-delay: -0.816s;
}
 .particle:nth-child(37) {
	 transform: translate(63vw, 3vh);
	 animation-duration: 1.71s;
	 animation-delay: -0.2907s;
}
 .particle:nth-child(38) {
	 transform: translate(70vw, 76vh);
	 animation-duration: 1.74s;
	 animation-delay: -0.3306s;
}
 .particle:nth-child(39) {
	 transform: translate(88vw, 21vh);
	 animation-duration: 1.61s;
	 animation-delay: -1.2558s;
}
 .particle:nth-child(40) {
	 transform: translate(22vw, 80vh);
	 animation-duration: 1.26s;
	 animation-delay: -0.063s;
}
 .particle:nth-child(41) {
	 transform: translate(8vw, 12vh);
	 animation-duration: 1.09s;
	 animation-delay: -0.0218s;
}
 .particle:nth-child(42) {
	 transform: translate(93vw, 24vh);
	 animation-duration: 1.84s;
	 animation-delay: -0.1656s;
}
 .particle:nth-child(43) {
	 transform: translate(13vw, 75vh);
	 animation-duration: 1.31s;
	 animation-delay: -0.5109s;
}
 .particle:nth-child(44) {
	 transform: translate(91vw, 56vh);
	 animation-duration: 1.49s;
	 animation-delay: -0.3874s;
}
 .particle:nth-child(45) {
	 transform: translate(10vw, 89vh);
	 animation-duration: 1.76s;
	 animation-delay: -0.1936s;
}
 .particle:nth-child(46) {
	 transform: translate(98vw, 9vh);
	 animation-duration: 1.79s;
	 animation-delay: -1.0561s;
}
 .particle:nth-child(47) {
	 transform: translate(80vw, 66vh);
	 animation-duration: 1.52s;
	 animation-delay: -0.3496s;
}
 .particle:nth-child(48) {
	 transform: translate(77vw, 21vh);
	 animation-duration: 1.11s;
	 animation-delay: -0.4551s;
}
 .particle:nth-child(49) {
	 transform: translate(55vw, 55vh);
	 animation-duration: 1.64s;
	 animation-delay: -1.64s;
}
 .particle:nth-child(50) {
	 transform: translate(88vw, 12vh);
	 animation-duration: 1.72s;
	 animation-delay: -0.8772s;
}
 .particle:nth-child(51) {
	 transform: translate(87vw, 99vh);
	 animation-duration: 1.25s;
	 animation-delay: -0.0375s;
}
 .particle:nth-child(52) {
	 transform: translate(33vw, 26vh);
	 animation-duration: 1.02s;
	 animation-delay: -0.3162s;
}
 .particle:nth-child(53) {
	 transform: translate(50vw, 37vh);
	 animation-duration: 1.47s;
	 animation-delay: -1.4259s;
}
 .particle:nth-child(54) {
	 transform: translate(15vw, 26vh);
	 animation-duration: 1.91s;
	 animation-delay: -1.6044s;
}
 .particle:nth-child(55) {
	 transform: translate(14vw, 79vh);
	 animation-duration: 1.78s;
	 animation-delay: -1.2994s;
}
 .particle:nth-child(56) {
	 transform: translate(21vw, 89vh);
	 animation-duration: 1.66s;
	 animation-delay: -0.332s;
}
 .particle:nth-child(57) {
	 transform: translate(91vw, 11vh);
	 animation-duration: 1.9s;
	 animation-delay: -0.589s;
}
 .particle:nth-child(58) {
	 transform: translate(100vw, 20vh);
	 animation-duration: 1.55s;
	 animation-delay: -1.24s;
}
 .particle:nth-child(59) {
	 transform: translate(78vw, 95vh);
	 animation-duration: 1.45s;
	 animation-delay: -0.7685s;
}
 .particle:nth-child(60) {
	 transform: translate(22vw, 79vh);
	 animation-duration: 1.84s;
	 animation-delay: -0.1472s;
}
 .particle:nth-child(61) {
	 transform: translate(57vw, 80vh);
	 animation-duration: 1.58s;
	 animation-delay: -0.8216s;
}
 .particle:nth-child(62) {
	 transform: translate(62vw, 63vh);
	 animation-duration: 1.69s;
	 animation-delay: -0.3718s;
}
 .particle:nth-child(63) {
	 transform: translate(77vw, 100vh);
	 animation-duration: 1.18s;
	 animation-delay: -1.18s;
}
 .particle:nth-child(64) {
	 transform: translate(21vw, 96vh);
	 animation-duration: 1.08s;
	 animation-delay: -0.2484s;
}
 .particle:nth-child(65) {
	 transform: translate(96vw, 12vh);
	 animation-duration: 1.85s;
	 animation-delay: -1.6095s;
}
 .particle:nth-child(66) {
	 transform: translate(51vw, 47vh);
	 animation-duration: 1.66s;
	 animation-delay: -1.1288s;
}
 .particle:nth-child(67) {
	 transform: translate(17vw, 12vh);
	 animation-duration: 1.38s;
	 animation-delay: -0.8556s;
}
 .particle:nth-child(68) {
	 transform: translate(83vw, 86vh);
	 animation-duration: 1.63s;
	 animation-delay: -0.2445s;
}
 .particle:nth-child(69) {
	 transform: translate(73vw, 5vh);
	 animation-duration: 1.3s;
	 animation-delay: -0.468s;
}
 .particle:nth-child(70) {
	 transform: translate(46vw, 44vh);
	 animation-duration: 1.54s;
	 animation-delay: -1.5092s;
}
 .particle:nth-child(71) {
	 transform: translate(67vw, 57vh);
	 animation-duration: 1.91s;
	 animation-delay: -0.8595s;
}
 .particle:nth-child(72) {
	 transform: translate(24vw, 52vh);
	 animation-duration: 1.07s;
	 animation-delay: -0.0428s;
}
 .particle:nth-child(73) {
	 transform: translate(17vw, 90vh);
	 animation-duration: 1.37s;
	 animation-delay: -0.7124s;
}
 .particle:nth-child(74) {
	 transform: translate(96vw, 35vh);
	 animation-duration: 1.75s;
	 animation-delay: -0.7525s;
}
 .particle:nth-child(75) {
	 transform: translate(41vw, 45vh);
	 animation-duration: 1.21s;
	 animation-delay: -0.4961s;
}
 .particle:nth-child(76) {
	 transform: translate(82vw, 47vh);
	 animation-duration: 1.18s;
	 animation-delay: -1.0856s;
}
 .particle:nth-child(77) {
	 transform: translate(13vw, 62vh);
	 animation-duration: 1.01s;
	 animation-delay: -0.303s;
}
 .particle:nth-child(78) {
	 transform: translate(4vw, 67vh);
	 animation-duration: 1.9s;
	 animation-delay: -1.881s;
}
 .particle:nth-child(79) {
	 transform: translate(16vw, 81vh);
	 animation-duration: 1.8s;
	 animation-delay: -0.27s;
}
 .particle:nth-child(80) {
	 transform: translate(40vw, 81vh);
	 animation-duration: 1.7s;
	 animation-delay: -0.085s;
}
 .particle:nth-child(81) {
	 transform: translate(37vw, 36vh);
	 animation-duration: 1.61s;
	 animation-delay: -1.1592s;
}
 .particle:nth-child(82) {
	 transform: translate(45vw, 98vh);
	 animation-duration: 1.61s;
	 animation-delay: -0.9177s;
}
 .particle:nth-child(83) {
	 transform: translate(31vw, 24vh);
	 animation-duration: 1.22s;
	 animation-delay: -0.9272s;
}
 .particle:nth-child(84) {
	 transform: translate(7vw, 84vh);
	 animation-duration: 1.81s;
	 animation-delay: -0.5068s;
}
 .particle:nth-child(85) {
	 transform: translate(36vw, 57vh);
	 animation-duration: 1.58s;
	 animation-delay: -1.185s;
}
 .particle:nth-child(86) {
	 transform: translate(55vw, 55vh);
	 animation-duration: 1.37s;
	 animation-delay: -0.3014s;
}
 .particle:nth-child(87) {
	 transform: translate(73vw, 60vh);
	 animation-duration: 1.87s;
	 animation-delay: -1.2529s;
}
 .particle:nth-child(88) {
	 transform: translate(23vw, 59vh);
	 animation-duration: 1.2s;
	 animation-delay: -0.036s;
}
 .particle:nth-child(89) {
	 transform: translate(4vw, 17vh);
	 animation-duration: 1.61s;
	 animation-delay: -0.644s;
}
 .particle:nth-child(90) {
	 transform: translate(22vw, 98vh);
	 animation-duration: 1.44s;
	 animation-delay: -1.1952s;
}
 .particle:nth-child(91) {
	 transform: translate(55vw, 59vh);
	 animation-duration: 1.34s;
	 animation-delay: -0.1474s;
}
 .particle:nth-child(92) {
	 transform: translate(86vw, 47vh);
	 animation-duration: 1.37s;
	 animation-delay: -0.9179s;
}
 .particle:nth-child(93) {
	 transform: translate(93vw, 61vh);
	 animation-duration: 1.14s;
	 animation-delay: -0.0456s;
}
 .particle:nth-child(94) {
	 transform: translate(13vw, 99vh);
	 animation-duration: 1.75s;
	 animation-delay: -0.175s;
}
 .particle:nth-child(95) {
	 transform: translate(17vw, 99vh);
	 animation-duration: 1.05s;
	 animation-delay: -0.7665s;
}
 .particle:nth-child(96) {
	 transform: translate(81vw, 4vh);
	 animation-duration: 1.02s;
	 animation-delay: -0.51s;
}
 .particle:nth-child(97) {
	 transform: translate(13vw, 67vh);
	 animation-duration: 1.69s;
	 animation-delay: -0.6929s;
}
 .particle:nth-child(98) {
	 transform: translate(6vw, 88vh);
	 animation-duration: 1.5s;
	 animation-delay: -1.29s;
}
 .particle:nth-child(99) {
	 transform: translate(55vw, 27vh);
	 animation-duration: 1.16s;
	 animation-delay: -1.0672s;
}
 .particle:nth-child(100) {
	 transform: translate(23vw, 41vh);
	 animation-duration: 1.71s;
	 animation-delay: -0.1026s;
}
 .particle:nth-child(101) {
	 transform: translate(32vw, 47vh);
	 animation-duration: 1.62s;
	 animation-delay: -0.5184s;
}

 @keyframes shoot {
	 0% {
		 transform: translate(50vw, 50vh);
	}
}
 @keyframes fade {
	 to {
		 opacity: 0;
	}
}
 

/* Particle CSS End */