@media screen and (max-width:430px) {
    .navToggleWhite{
        filter: brightness(1);
    }
    .navToggleWDark{
        filter: brightness(0.1);
    }

    .dropdown-height{
        height: 100vh;
        background-color: #333;
    }

    .show{
        width: 100%;
        overflow-x: hidden;
        display: block;
        padding: 0.2rem;


    }
 
    .navalign{
        margin: 3px !important;
    }
    .navbar-nav{
        height: 90%;
        margin: 2% 1px;
        display: flex;
        flex-direction: column;
        padding-left: 0;
        /* justify-content: space-evenly; */
        align-items: center;
        margin: 3px !important;
        
    }
    .nav-item{
    font-weight: bolder !important;
    font-size: 15px;
    width: 100%;
    padding: 0px 4px;
    border-bottom: 2px solid black;
    }

    .nav-changed-heading{
        display: block !important;
        font-family: sans-serif;
        font-size:1.5em;
        padding:1rem;
        text-align: left;
    }

    .dropdown-arrow {
        border: solid #fff;
        border-width: 0 3px 3px 0;
        display: inline-block;
        margin: 5px;
        padding: 5px;
        border-radius: 3px;
        position: absolute ;
        right: 1rem;
        transition: padding 0.5s ease-in;
      }

      .dropdown-arrow-up {
        color: white;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }
      
      .dropdown-arrow-down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }

      .navbar .dropdown-menu {
        position: relative !important;
        margin: 5px;
        background-color: none !important;
    }
 

    .drop-option-show{
        display: inline-block;
    }
    
    .drop-btn-focus{
        background-color: #555 !important;

    }

    

    .dropdown{
        text-align: left;
    }
    

    .nav-link{
        border-bottom: 2px solid rgb(50, 47, 47);
    }
   

 
    .dropdown-box{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: space-around;
        justify-content: space-between;
        width: 100%;
        text-align: left;
    }
    .dropdown-column{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-evenly;
    }

    .dropdown-para{
        display: none;
    }

    .dropdown-button{
        margin:3px 0px;
        text-align: left;
        border: none;
        border-bottom: 2px solid ;
        outline: none;
    }
    .dropdown-link-text{
        display: inline-block;
        width :100%;
        color: #fff !important;
        background-color: #555;
        font-family: sans-serif;
        text-align: left;
        font-size: 18px !important;
        padding: 10px 20px !important;
        border: none;
        outline: none;

    }

    .dropdown-link-text:active{
        background-color: #ddd;
    }
}