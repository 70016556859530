.div-img-carousel {
  height: 65vh;
  width: 97vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1%;
  overflow: hidden;
  }

  .div-img-carousel .nextBtn , .div-img-carousel .prevBtn{
    font-size: 20px;
    background: #af8397;
    color: white;
    padding: 2px 9px;
    outline: none;
    border: 2px solid #af8397;
    border-radius: 22px;
    position: relative;
    z-index: 2;
  }
  .div-img-carousel .nextBtn{
    right: -90vw;

  }
  .div-img-carousel .prevBtn{
    left: 2vw;
  }
  
  .div-img-carousel .carousel {
    display: flex;
    height: 64vh;
    align-items: center;
    justify-content: space-between;
    position: relative;
    left: 0.5rem;
  }
  
  .div-img-carousel .carousel-box {
    display: flex;
    height: 97%;
    width: 27vw;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top:.5%;
    margin-bottom: .5%;
    margin-right: 1%;
    background-repeat : no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    transition: all 0.85s ease-in 0.2s;
    padding: 1% 1%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  }
  .div-img-carousel .carousel-box.mob {
    display: none;
    
  }
  
  
  
  .div-img-carousel .carousel-box p {
    /* z-index: 1;
    position: relative;
    top: -40%; */
    color: white;
    margin-bottom: .5rem;
    text-align: left;
  }
  
  .div-img-carousel .carousel-box .carousel-title{
  font-size: 2rem;
    
  }

  .div-img-carousel .carousel-box .carousel-text{
    font-size: 1.2rem;

  }
  .div-img-carousel .carousel-box .read-more-btn{
    font-size: 1rem;
    margin: 0% 0%;
    color: #af8397;

  }
  .div-img-carousel .carousel-box .icon{
    color: #af8397;

  }
  @media only screen and (max-width: 426px){
    .div-img-carousel {
      height: 76vh;
      padding: 0px 0.4%;
      
      }
    
      .div-img-carousel .nextBtn , .div-img-carousel .prevBtn{
        outline: none;
        font-size: 1.05rem;
        position: absolute;


      }
      .div-img-carousel .nextBtn{
        right:2vw;
        
      }
      .div-img-carousel .prevBtn{
        
      }
      
      .div-img-carousel .carousel {
        height: 99%;
        left: 0;
        padding: 0 1%;
        
      }

      
      .div-img-carousel .carousel-box {
        display: none;
        
      }
      .div-img-carousel .carousel-box.mob {
        display: flex;
        
      }
      .div-img-carousel .carousel-box.mob {
        width: 100vw;
        
      }
      
      
      .div-img-carousel .carousel-box.mob p {
        
      }
      
      .div-img-carousel .carousel-box.mob .carousel-title{
      font-size: 1.35rem;
        
      }
    
      .div-img-carousel .carousel-box.mob .carousel-text{
       font-size: 0.9rem;
    
      }
      .div-img-carousel .carousel-box.mob .read-more-btn{
        
    
      }
      .div-img-carousel .carousel-box.mob .icon{
    
      }
    
  }